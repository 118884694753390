import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import BackgroundContainer from "../../components/BackgroundContainer";
import Header from "../../components/Header";
import Navigator from "../../components/Navigator";
import ProductsList from "../../components/ProductsList";
import PointLight from "../../components/PointLight";

import { ProductInfo, Products as ProductsData } from "../../services/ProductsInfo";

import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Controller, Navigation } from "swiper/modules";

import '../../styles/content.css';
import 'swiper/css';
import 'swiper/css/navigation';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const modalVariants = {
  hidden: {
    scale: 0,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: [0.04, 0.62, 0.23, 0.98] 
    }
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.04, 0.62, 0.23, 0.98]
    }
  },
  exit: {
    scale: 0.95,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: [0.04, 0.62, 0.23, 0.98]
    }
  }
};

export default function Products() {
  const [swiperControl, setSwiperControl] = useState<SwiperClass>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductInfo>();

  const handleProductOpen = (product: ProductInfo) => {
    setModalVisible(true);
    setSelectedProduct(product);
  }

  const handleProductClose = () => {
    setModalVisible(false);
    setSelectedProduct(undefined);
  }

  return (
    <div className="Products flex flex-col h-full">
      <Header selectedId="products" device="desktop"/>
      <BackgroundContainer className="px-2 lg:px-10 xl:px-32 py-2 2xl:py-12 flex flex-col items-center">
        <div className="z-10 absolute flex justify-center items-center pointer-events-none">
          <PointLight 
            larger
            tiny
          />  
        </div>
        <h1 className='text-white text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-bold leagueSpartanFont'>
          Que serviços oferecemos?
        </h1>

        <ProductsList 
          products={ProductsData}
          onProductOpen={handleProductOpen}
        />
       
        <div className="flex gap-x-4 absolute bottom-4 lg:bottom-4 2xl:bottom-24">
          <button type="button" className="bg-gradient-to-t from-[#FF6B00] to-[#FFA41C] w-44 2xl:w-48 p-3 text-xs 2xl:text-sm font-black rounded-md mt-2 flex gap-x-2 items-center">
            <img className="h-4" src="/images/coin.png" alt="vetor de moedas" />
            Solicite um orçamento
          </button>
        </div>

        <Navigator selectedId="products" className="items-start w-full px-2 lg:px-10 xl:px-32" />
      </BackgroundContainer>
      <AnimatePresence>
        {modalVisible && (
          <div 
            className="product-modal w-screen h-screen fixed z-40 flex justify-center items-center" 
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
            onClick={handleProductClose}
          >
            <motion.div
              className="w-[500px] min-h-[550px] bg-black/90 backdrop-blur-sm rounded-xl border border-white/20 text-white p-8 flex flex-col gap-6"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              onClick={(e) => e.stopPropagation()}
            >
              <h2 className="font-black text-3xl bg-gradient-to-r from-orange-500 to-yellow-400 bg-clip-text text-transparent">
                {selectedProduct!.title}
              </h2>
              <div className="text-sm text-gray-300 max-h-40 overflow-y-auto leading-relaxed" dangerouslySetInnerHTML={{__html: selectedProduct!.text}} />
              {selectedProduct?.videoUrl && (
                <div className="relative group">
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent rounded-xl z-10" />
                  {selectedProduct.videoUrl && (
                    <video 
                      className="w-full h-64 object-cover rounded-xl transition-transform duration-300 group-hover:scale-[1.02]"
                      src={selectedProduct.videoUrl}
                      autoPlay 
                      muted 
                      loop 
                      playsInline
                    ></video>
                  )}
                </div>
              )}
              {selectedProduct?.imagesUrl && (
                <div className="flex">
                  {selectedProduct.imagesUrl.length > 1 && (
                    <button
                      className="z-40 text-[#ff9900]"
                    >
                      <IoIosArrowBack
                        size={35}
                        className="hover:scale-110 transition-all duration-300"
                      />
                    </button>
                  )}
                  <Swiper
                    onSwiper={(swiper: SwiperClass) => setSwiperControl(swiper)}
                    direction="horizontal"
                    loop={true}
                    slidesPerView={1}
                    modules={[Navigation, Controller]}
                  >
                    {selectedProduct.imagesUrl.map((imageUrl) => (
                      <SwiperSlide key={imageUrl}>
                        <img
                          className="w-full h-64 object-cover rounded-xl transition-transform duration-300 group-hover:scale-[1.02]"
                          src={imageUrl}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  {selectedProduct.imagesUrl.length > 1 && (
                    <button
                      onClick={() => swiperControl!.slideNext()}
                      className="z-40 text-[#ff9900]"
                    >
                      <IoIosArrowForward
                        size={35}
                        className="hover:scale-110 transition-all duration-300"
                      />
                    </button>
                  )}
                </div>
              )}
              <button 
                className="see-more-button bg-gradient-to-r from-orange-500 to-yellow-400 text-black font-bold p-2 px-4 rounded-lg text-sm flex items-center gap-x-2 max-w-fit mt-auto hover:opacity-90 transition-opacity duration-200"
                onClick={handleProductClose}
              >
                <span className="text-lg">×</span>
                Veja menos
              </button>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}