import React from 'react';

interface ReviewProps {
  mobile?: boolean;
  client: string;
  desc: string;
  message: string;
  tag: string;
  review: number;
  img: string;
}

export default function ClientContainer({
  client,
  desc,
  message,
  tag,
  review,
  mobile,
  img
}: ReviewProps){
  const starsRender = [];

  const StarFillRender = () => (<img className='h-3' src={`${process.env.PUBLIC_URL}/images/estrelacheia.png`} alt="" />);
  const StarUnfillRender = () => (<img className='h-3' src={`${process.env.PUBLIC_URL}/images/estrelavazia.png`} alt="" />);

  for (let i = 1; i <= 5; i++) {
    if (i <= review) {
      starsRender.push(<StarFillRender />);
      continue;
    }
    starsRender.push(<StarUnfillRender />);
  }

  return(
    <div className={`flex flex-col items-center border-solid border-white border-2 rounded-md ${mobile ? '' : 'w-80'} pt-6 pb-3 px-3`}>
      <img className="rounded-full h-24 w-24" src={img} alt=""/>

      <h2 className="text-white font-black text-xl mt-2">{client}</h2>
      <h3 className="text-white text-center font-medium max-w-72 text-xs xl:text-sm">
        {desc}
      </h3>

      <div className={`border-y border-x border-white border-2 rounded p-2 mt-6 bg-gradient-to-b from-zinc-950 to-zinc-950 via-transparent w-full ${mobile ? '' : 'h-44'} flex flex-col`}>
        <p className="text-white text-xs font-medium grow">
          {message}
        </p>

        <div className="flex items-center place-content-between">
          <div className="simple-button text-black font-semibold bg-white rounded px-2 py-1 text-xs flex justify-center items-center w-fit">
            <p className='text-center'>{tag}</p>
          </div>

          <div className="flex gap-x-0.5 star-group">
            {starsRender}
          </div>
        </div>
      </div>
    </div>
  )
}