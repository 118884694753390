import React from "react";

interface HomeTitleProps {
  breakLine?: boolean;
}

export default function HomeTitle({ 
  breakLine = false 
}: HomeTitleProps) {
  return (
    <h1 className="text-xl md:text-4xl text-white font-bold leagueSpartanFont max-w-sm mt-4">
      Somos referência em {breakLine && <br />}
      tecnologia, para {breakLine && <br />}
      eventos corporativos {breakLine && <br />}
      e sociais.
    </h1>
  );
}