import React, { useEffect, useState } from 'react'

interface InfoButtonProps {
  onClick?: () => void;
}

export default function InfoButton({
  onClick
}: InfoButtonProps) {
  return(
    <button 
      className="info-button text-black font-bold bg-white rounded text-xs flex items-center gap-x-1 absolute bottom-1 right-1"
      onClick={onClick}
    >
      <img src="/images/search.png" alt="" className="h-3"/>
      Informações
    </button>
  )
}