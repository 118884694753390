import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import BackgroundSplitContainer from "../../components/BackgroundSplitContainer";
import Navigator from "../../components/Navigator";
import CompanyTitle from "../../components/CompanyTitle";
import CompanySubtitle from "../../components/CompanySubtitle";
import CompanyAbout from "../../components/CompanyAbout";
import ViewSolutions from "../../components/ViewSolutions";

export default function Company() {
  return (
    <div className="Company flex flex-col h-full">
      <Header selectedId="company" device="desktop"/>

      <BackgroundSplitContainer 
        imgUrl="/images/longer-back-rect.png"
        width={90}
        className="flex flex-col"
      >
        <CompanyTitle />
        <CompanySubtitle />
        <CompanyAbout />

        <div className="flex gap-x-4 mt-5 xl:mt-10 2xl:mt-0">
          <a 
            href="/contato" 
            className="bg-gradient-to-t from-[#FF6B00] to-[#FFA41C] w-44 p-3 text-xs font-black rounded-md mt-2 flex gap-x-2 items-center"
          >
            <img className="h-4" src="/images/coin.png" alt="vetor de moedas" />
            Solicite um orçamento
          </a>

          <ViewSolutions />
        </div>
      
      <Navigator selectedId="company"/>
      </BackgroundSplitContainer>
    </div>
  );
}