import { motion } from "framer-motion";
import React from "react";
import PointLight from "../PointLight";

interface BackgroundSplitContainerProps extends React.HTMLAttributes<HTMLElement> {
  imgUrl: string;
  children?: React.ReactNode;
  className?: string;
  width: number;
  style?: { [key: string]: any };
}

export default function BackgroundSplitContainer({
  width,
  children,
  className,
  style,
  imgUrl,
}: BackgroundSplitContainerProps) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: "tween", ease: "anticipate", duration: 1 }}
      className="relative flex-grow w-full flex flex-col overflow-hidden gap-y-8"
    >
      <div className={`relative z-20 px-10 xl:px-24 py-3 xl:py-6 2xl:py-24 h-full ${className}`}>
        {children}
      </div>
      <img
        src={imgUrl}
        alt="Background"
        className="absolute inset-0 w-full h-full object-cover"
        style={{
          width: `${width}vw`,
        }}
      />
      <div className="absolute w-full h-full z-20 px-48 py-24">
        <PointLight />
      </div>
    </motion.div>
  );
}
