import React, { useEffect, useState } from "react";
import Typist from "react-typist";

import Header from "../../components/Header";
import BackgroundContainer from "../../components/BackgroundContainer";
import Navigator from "../../components/Navigator";
import ContactTitle from "../../components/ContactTitle";
import ContactForm from "../../components/ContactForm";
import FindUsTitle from "../../components/FindUsTitle";
import PointLight from "../../components/PointLight";
import Map from "../../components/Map";
import FindUsContent from "../../components/FindUsContent";

export default function Contact() {
  return (
    <div className="Contact flex flex-col h-full">
      <Header selectedId="contact" device="desktop"/>

      <BackgroundContainer className="px-1 lg:px-10 xl:px-32 py-6 overflow-hidden">

        <div className="flex py-2 h-full justify-center">
          <div className="z-10 absolute flex justify-center items-center pointer-events-none">
            <PointLight 
              larger 
              tiny 
            />  
          </div>

          <div className="flex flex-col flex-1 text-center gap-y-4 max-w-xl">
            <ContactTitle />
            <ContactForm />
          </div>

          <div className="h-full border-2 2xl:border-3 border-white mx-4"></div>

          <div className="flex flex-col flex-1 text-center max-w-xl">
            <FindUsTitle />
            <FindUsContent />
          </div>
        </div>

        <Navigator selectedId="contact" />
      </BackgroundContainer>
    </div>
  );
}