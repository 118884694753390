import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { EventInfo } from "../../services/EventsInfo";

interface EventContainerProps {
  event: EventInfo;
  mobile?: boolean;
  onClick: () => void;
}

export default function EventContainer({
  event,
  mobile,
  onClick
}: EventContainerProps) {
  const [isHovering, setIsHovering] = useState<boolean>();

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className="relative event w-full"
      onClick={() => mobile && onClick()}
    >
      <div className="relative group">
        <AnimatePresence>
          {isHovering && (
            <motion.div 
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{ type: "tween", ease: "anticipate", duration: 0.35 }}
              className="absolute inset-0 bg-gradient-to-b from-black/80 to-transparent z-10" 
            />
          )}
        </AnimatePresence>
        <img 
          className={`${mobile ? 'h-20' : 'h-28 2xl:h-32'} w-full object-cover`} 
          src={event.imgsUrl[0]}
          alt="" 
        />
      </div>
      <AnimatePresence>
        {(!mobile && isHovering) && (
          <motion.div 
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{ type: "tween", ease: "anticipate", duration: 0.35 }}
            className="flex flex-col items-center justify-center gap-y-1 h-full w-full absolute top-0 text-center p-2 z-20"
          >
            <h3 className={`text-white font-bold text-sm ${mobile ? 'text-xs' : ''}`}>{event.title}</h3>
    
            <button 
              className="simple-button text-black font-bold bg-white p-1 rounded text-xs flex items-center gap-x-1"
              onClick={onClick}
            >
              <img src="/images/search.png" alt="" className="h-3"/>
              Informações
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
