import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence, color } from 'framer-motion';
import { RouteInfo } from '.';
import { useActiveSection } from '../../hooks/useActiveSection';

interface Props {
  selectedPageId?: "home" | "company" | "clients" | "contact" | "events" | "products";
  headerData: RouteInfo[];
}

const buttonVariants = {
  closed: {
    rotate: 0,
    translateY: 0,
    translateX: 0,
    opacity: 1,
    transition: { duration: 0.2 }
  },
  topToX: {
    rotate: 45,
    translateY: 17,
    translateX: 2,
    transition: { duration: 0.3 },
  },
  middleToX: {
    opacity: 0,
    transition: { duration: 0.2 }
  },
  bottomToX: {
    rotate: -45,
    translateY: -7,
    translateX: 2,
    transition: { duration: 0.3 }
  }
};

const menuVariants = {
  open: {
    x: 0,
    opacity: 0.95,
    transition: {
      type: 'spring',
      stiffness: 120,
      damping: 20
    }
  },
  closed: {
    x: '-100%',
    opacity: 0,
    transition: {
      type: 'spring',
      stiffness: 120,
      damping: 20
    }
  }
};

export default function MobileItems({ selectedPageId, headerData }: Props) {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const { activeSection, setActiveSection } = useActiveSection();

  const handleLinkClick = (id: string) => {
    setActiveSection(id);
  };

  return (
    <header className={`sticky bg-black top-0 z-40 w-screen flex py-3 px-6 items-center justify-between transition-all duration-300`}>
      <button 
        onClick={() => setMenuOpen(!isMenuOpen)}
        style={{ background: 'none', border: 'none', cursor: 'pointer', outline: 'none' }}
      >
        <svg width="25" height="20" viewBox="0 0 40 30">
          <motion.rect 
            width="40" 
            height="3" 
            fill="#fff"
            variants={buttonVariants}
            animate={isMenuOpen ? "topToX" : "closed"}
          />
          <motion.rect 
            y="13" 
            width="40" 
            height="3" 
            fill="#fff"
            variants={buttonVariants}
            animate={isMenuOpen ? "middleToX" : "closed"}
          />
          <motion.rect 
            y="26" 
            width="40" 
            height="3" 
            fill="#fff"
            variants={buttonVariants}
            animate={isMenuOpen ? "bottomToX" : "closed"}
          />
        </svg>
      </button>
      <a href={headerData[0].route}>
        <img className='h-10' src="/images/cdapepvc87qotqq59sdq.png" alt="Logo Abe Eventos" />
      </a>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
            className="absolute top-full h-screen left-0 w-screen bg-black shadow-lg"
          >
            {headerData.map(item => (
              <a 
                key={item.id} 
                onClick={() => handleLinkClick(item.id)} 
                className={`headerlink leagueSpartanFont block px-6 py-3 text-3xl transition-all duration-300 ${activeSection == item.id ? 'text-orange-400 font-semibold' : 'text-white'} ${item.id}`}
              >
                {item.content}
              </a>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}
