import React from 'react'
import Link from './Link'

interface NavigatorProps {
  selectedId?: "home" | "company" | "clients" | "contact" | "events" | "products";
  className?: string;
  fill?: boolean;
}

const navigatorData = [
  {
    id: "home",
    route: "/",
    content: ''
  },
  {
    id: "company",
    route: "/empresa",
    content: ''
  },
  {
    id: "clients",
    route: "/clientes",
    content: ''
  },
  {
    id: "contact",
    route: "/contato",
    content: ''
  },
  {
    id: "events",
    route: "/eventos",
    content: ''
  },
  {
    id: "products",
    route: "/produtos",
    content: ''
  }
]

export default function Navigator({
  fill, 
  selectedId, 
  className
}: NavigatorProps){
  return(
    <ul className={`${fill ? 'w-full ms-64' : ''} flex gap-x-2 items-center absolute bottom-5 2xl:bottom-14 ${className}`}>
      {navigatorData.map((data) => 
        (<Link key={`link-${data.id}`} route={data.route} selected={data.id == selectedId}>{data.content}</Link>
      ))}
    </ul>
  )
}









































    // <ul className="flex gap-x-2 items-center">
    //   <li>
    //     <a href=""><div className="w-3.5 h-3.5 bg-white rounded-full"></div></a>
    //   </li>

    //   <li>
    //     <a href=""><div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div></a>
    //   </li>

    //   <li>
    //     <a href=""><div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div></a>
    //   </li>
      
    //   <li>
    //     <a href=""><div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div></a>
    //   </li>

    //   <li>
    //     <a href=""><div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div></a>
    //   </li>

    //   <li>
    //     <a href=""><div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div></a>
    //   </li>
    // </ul>