import React from "react";

interface ViewSolutionProps {
  mobile?: boolean;
}

export default function ViewSolutions({mobile}: ViewSolutionProps) {
  return (
    <button type="button" className={`w-44 p-3 text-white ${mobile ? 'text-md' : 'text-xs'} font-black mt-2 flex gap-x-2 simple-button items-center`}>
      <img className="h-3" src="/images/listsolid.png" alt="vetor de lista" />
      Ver soluções
    </button>
  );
}