export interface ProductInfo {
  title: string;
  text: string;
  videoUrl?: string;
  imagesUrl?: string[];
}

export const Products: ProductInfo[] = [
  {
    title: "Tour 360°",
    text: "Experiência imersiva que permite explorar ambientes e locais virtualmente como se estivesse presente. Ideal para visualizações de propriedades imobiliárias, exposições de arte e eventos virtuais, o Tour 360° oferece uma visão completa de qualquer ambiente através de uma interface fácil de navegar, permitindo aos usuários moverem-se livremente e observarem detalhes que passariam despercebidos em fotos comuns.",
    videoUrl: "/videos/itgu2enba2suuswgclbw.mp4"
  },
  {
    title: "Catracas de Controle de Acesso",
    text: "Catracas avançadas equipadas com tecnologia de leitura QR Code proporcionam um método eficiente e seguro de controle de acesso a eventos, edifícios corporativos e áreas restritas. Essas catracas melhoram significativamente o fluxo de entrada e saída, reduzindo filas e aumentando a segurança ao validar entradas com precisão quase instantânea, tornando o processo tanto mais moderno quanto mais prático.",
    videoUrl: '/videos/cchzsuv2sricmpfip1nn.mp4'
  },
  {
    title: "Totens de Credenciamento",
    text: "Solução autônoma e moderna para o credenciamento rápido em eventos, feiras e conferências. Os totens permitem o autoatendimento para o registro e impressão de crachás, integrando-se facilmente com sistemas de gerenciamento de eventos e bases de dados. Isso minimiza o tempo de espera, maximiza a eficiência organizacional e melhora a experiência dos participantes.",
    videoUrl: '/videos/ydxmogsf66h3kpwv0olp.mp4'
  },
  {
    title: "Arena Silenciosa",
    text: "Tecnologia inovadora que oferece uma experiência auditiva única por meio de fones de ouvido sem fio. Em eventos onde múltiplas apresentações ou traduções ocorrem simultaneamente no mesmo espaço, a Arena Silenciosa permite aos participantes escolher individualmente o áudio que desejam ouvir sem interferências externas, garantindo uma experiência personalizada e focada.",
    videoUrl: '/videos/mcsgjqeqzydmfeptlcrh.mp4'
  },
  {
    title: "Tour Guide",
    text: "Serviço de guias profissionais para eventos de grande porte, como exposições, feiras e espaços turísticos. Com o auxílio de equipamentos de áudio, os guias proporcionam informações e curiosidades relevantes, enriquecendo a experiência cultural e informativa dos visitantes. Ideal para grupos grandes, onde a comunicação eficaz é fundamental.",
    imagesUrl: [
      "/images/y5tpctoif18tkewz2e9k.jpg"
    ]
  },
  {
    title: "Produção de Conteúdo",
    text: "Desenvolvimento completo de conteúdo multimídia, incluindo vídeos, fotos, artigos e material promocional para eventos, campanhas de marketing e mais. Nossa equipe especializada trabalha para criar conteúdos que não apenas informam, mas também engajam o público-alvo, usando estratégias de conteúdo adaptadas às necessidades e objetivos específicos de cada cliente.",
    imagesUrl: [
      "/images/wenljyr45lwml7s5g2sz.jpg"
    ]
  },
  {
    title: "Conectividade e Internet",
    text: "Nossa infraestrutura de conectividade, incluindo internet 4G corporativa e internet via satélite Starlink, assegura uma conexão estável em qualquer localidade. Com altas velocidades de upload e download, essa tecnologia é essencial para transmissões ao vivo e eventos híbridos, proporcionando uma experiência contínua e sem interrupções para os participantes.",
    imagesUrl: [
      "/images/mdptbsbrq2dreeymv2gr.jpg"
    ]
  },
  {
    title: "Desenvolvimento de Aplicativos e Jogos Interativos",
    text: "Oferecemos o desenvolvimento de aplicativos personalizados para facilitar inscrições, engajamento e interação em tempo real. Também criamos quizzes, jogos e interfaces interativas que incentivam a participação do público. Essas soluções são ideais para eventos corporativos que buscam aumentar o engajamento e criar experiências diferenciadas.",
    imagesUrl: [
      "/images/gejuwmbibbinlg8kuk1a.png"
    ]
  },
  {
    title: "Projetos 3D e Estruturais",
    text: "Desenvolvemos projetos em 2D e 3D para auxiliar na disposição estratégica dos espaços e estrutura dos estandes, proporcionando uma visualização prévia detalhada e garantindo que o evento aproveite ao máximo o layout disponível.",
    imagesUrl: [
      "/images/ljd3tgboj49wybr6ngw2.jpg",
      "/images/jjvnx8etbfncr0ugqsso.jpg",
      "/images/gb47yygfl1hzfmxnptcb.jpg",
      "/images/ydq0pgdqlhxcm4v9fxi0.jpg"
    ]
  },
  {
    title: "Locação de Equipamentos",
    text: "A ABE Eventos disponibiliza uma ampla gama de equipamentos para locação, como TVs de várias polegadas, notebooks, impressoras e tecnologia LED holográfica. Esses equipamentos são essenciais para apresentações visuais, estandes e áreas de interação, proporcionando uma experiência completa e inovadora para os participantes.",
    imagesUrl: [
      "/images/zjg52beeu7xtopyasoef.jpg",
      "/images/vzh0o9nenompruch5ksp.jpg",
      "/images/ttpq2teodomoqcybhxcu.jpg",
      "/images/a1sjc0jhwih4v6urraat.jpg",
      "/images/uyjcmoihta8uxa3jznjb.jpg"
    ]
  },
  {
    title: "Totens Interativos",
    text: `Oferecemos uma ampla linha de totens interativos, ideais para aumentar a interatividade e personalização em eventos. Nossos modelos incluem:
    <ul style="margin-left: 20px; margin-top: 10px; margin-bottom: 10px;">
      <li><strong>Totem Digital Touch Screen 43"</strong>: Tela interativa de 43" com frame touch screen, ideal para navegação de conteúdo e autoatendimento.</li>
      <li><strong>Totem Digital com Impressora de Etiquetas</strong>: Adiciona a funcionalidade de impressão térmica, útil para credenciamento rápido e impressão de etiquetas no local.</li>
      <li><strong>Totem Mesa Digital Touch Screen 43"</strong>: Modelo de mesa para experiências mais imersivas, facilitando o acesso a informações em um formato de mesa interativa.</li>
      <li><strong>Totem Tablet com Impressora e Leitor de Código</strong>: Equipado com leitor de código de barras e QR Code e impressora térmica, ideal para feiras e estandes que precisam de registro rápido.</li>
      <li><strong>Totem Ring Fotográfico</strong>: Oferece impressões fotográficas instantâneas e compartilhamento por QR Code, perfeito para criar momentos memoráveis em eventos sociais e corporativos.</li>
      <li><strong>Totens de Carregamento</strong>: Desde torres de carregamento de celular até totens com gavetas e capacidade de carga simultânea para múltiplos dispositivos, garantindo que os participantes estejam sempre conectados.</li>
    </ul>
    Esses totens personalizáveis agregam valor em eventos ao fornecer pontos de interação, informações e suporte prático para os convidados.`,
  imagesUrl: [
    "/images/cohebfufwzptn0ukzk2y.jpg",
    "/images/jsovthi6njqinqzmnl8o.jpg",
    "/images/vkcp1eqf7d9ochill2rg.jpg",
    "/images/jcbd6iyearqargddsiub.jpg",
    "/images/kgqz9dvwcu67jvowf5r3.jpg"
  ]
  },
]