import React, { useEffect, useState } from "react";
import BackgroundSplitContainer from "../../components/BackgroundSplitContainer";
import Header from "../../components/Header";
import '../../styles/content.css';
import Navigator from "../../components/Navigator";
import LeaderStatistc from "../../components/StatistcsPhrases/Leader";
import ApprovalStatistic from "../../components/StatistcsPhrases/Approval";
import HomeTitle from "../../components/HomeTitle";
import BudgetButton from "../../components/BudgetButton";
import GrowthStatistic from "../../components/StatistcsPhrases/Growth";
import ServedStatistic from "../../components/StatistcsPhrases/Served";
        
export default function Home() {
  return (
    <div className="Home flex flex-col h-full">
      <Header selectedId="home" device="desktop"/>
      <BackgroundSplitContainer 
        imgUrl={`${process.env.PUBLIC_URL}/images/back-rect.png`}
        width={70}
        className="flex flex-col"
      >
        <HomeTitle />
        <section className="text-white mt-8 text-xs 2xl:text-sm font-black flex grow">
          <div className="grid grid-cols-2 gap-2">
            <LeaderStatistc breakLine />
            <ServedStatistic breakLine />
            <ApprovalStatistic breakLine />
            <GrowthStatistic breakLine />
          </div>
        </section>
        
        <BudgetButton/>
        
        <Navigator selectedId="home"/>
      </BackgroundSplitContainer>
    </div>
  );
}